.button {
  z-index: 2;
  color: white;

  &:hover {
    color: cyan;
  }
}

.imageItem {
  margin: auto;
  cursor: pointer;
  object-fit: contain;
  background-color: light-dark(
    var(--mantine-color-gray-1),
    var(--mantine-color-gray-9)
  );

  height: auto;
  width: 100%;
}

.grid {
  padding: var(--mantine-spacing-lg) var(--mantine-spacing-md);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  gap: 5px;

  & > * {
    display: grid;
  }

  @media (max-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1050px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
}

.categoryItem {
  &:hover {
    cursor: pointer;
    color: #ababab;
  }
}

.galleryGroups {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: light-dark(#202020, white);

  @media (max-width: 1250px) {
    flex-direction: column;
  }
}
