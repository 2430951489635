.logo {
  display: flex;

  flex-direction: column;

  justify-content: space-between;
  align-items: center;
  padding: 80px 0;

  @media (max-width: 750px) {
    padding: 0;
    h1 {
      font-size: 50px;
    }
  }
}

.section {
  padding: 80px 0;

  @mixin light {
    border-bottom: 1px solid var(--mantine-color-gray-1);
  }

  @mixin dark {
    border-bottom: 1px solid var(--mantine-color-dark-9);
  }
}

.content {
  margin: auto;
  max-width: 1300px;
}

.grid {
  display: grid;

  grid-template-columns: repeat(3, 1fr);

  gap: 40px;

  padding: var(--mantine-spacing-lg) var(--mantine-spacing-md);

  @media (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }

  position: relative;
}

.graySection {
  background: light-dark(
    var(--mantine-color-gray-0),
    var(--mantine-color-dark-8)
  );
}
