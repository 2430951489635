.height {
  height: 65px;
}

.navigation {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  max-width: 1300px;
  margin: auto;
  padding: 0 var(--mantine-spacing-md);

  @media (max-width: 600px) {
    grid-template-columns: 1fr 9fr;
  }
}

.list {
  display: inline-flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  gap: 35px;
}

.link {
  &:hover {
    cursor: pointer;
    color: #ccc;
  }
}

.backColor {
  background-color: light-dark(white, var(--mantine-color-dark-9));
}

.sticky {
  z-index: 1;
  position: fixed;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  animation: slideDown 0.35s ease-out;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.hiddenMobile {
  @media (max-width: 600px) {
    display: none;
  }
}

.hiddenDesktop {
  @media (min-width: 600px) {
    display: none;
  }
}

.mobileLink {
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  height: 42px;
  display: flex;
  align-items: center;
  width: 100%;
}

.divider {
  color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
}
