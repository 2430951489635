.header {
  z-index: 10;
  display: flex;
  justify-content: space-between;
  background-color: black;
  color: white;
}

.button {
  z-index: 2;
  color: white;

  &:hover {
    color: cyan;
  }
}

.hideOnMobile {
  @media (max-width: 550px) {
    display: none;
  }
}
