.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px var(--mantine-spacing-md);
  max-width: 1300px;

  @media (max-width: 550px) {
    flex-direction: column;
  }
}

.icons {
  color: light-dark(#868e96, #e9ecef);
}
