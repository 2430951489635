.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.card {
  color: light-dark(black, white);
  overflow: unset;
  padding-top: 38px;
  background-color: inherit;
  &:hover {
    .hoverHighlight {
      background-size: 100% 10px;
    }
  }
}

.hoverHighlight {
  display: inline;
  transition-duration: 0.5s;
  background-position: 0 100%;
  transition-property: background-size;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  @mixin light {
    background-image: linear-gradient(to right, #bbf7d0, #dcfce7);
  }

  @mixin dark {
    background-image: linear-gradient(to right, #6b21a8, #581c87);
  }

  background-repeat: no-repeat;
  background-size: 0 10px;
}

.imageContainer {
  transition: transform 150ms ease, box-shadow 150ms ease;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  &:hover {
    transform: scale(1.02);
  }
}

.footer {
  color: #666;
}
