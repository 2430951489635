.BlogCard_flexColumn__xGb4o {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.BlogCard_card__BwWq_ {
  color: black;
}

[data-mantine-color-scheme='dark'] .BlogCard_card__BwWq_ {
  color: white;
}

.BlogCard_card__BwWq_ {
  overflow: unset;
  padding-top: 38px;
  background-color: inherit;
}

.BlogCard_card__BwWq_:hover .BlogCard_hoverHighlight__dZ5JZ {
      background-size: 100% 10px;
    }

.BlogCard_hoverHighlight__dZ5JZ {
  display: inline;
  transition-duration: 0.5s;
  background-position: 0 100%;
  transition-property: background-size;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

[data-mantine-color-scheme='light'] .BlogCard_hoverHighlight__dZ5JZ {
    background-image: linear-gradient(to right, #bbf7d0, #dcfce7);
}

[data-mantine-color-scheme='dark'] .BlogCard_hoverHighlight__dZ5JZ {
    background-image: linear-gradient(to right, #6b21a8, #581c87);
}

.BlogCard_hoverHighlight__dZ5JZ {

  background-repeat: no-repeat;
  background-size: 0 10px;
}

.BlogCard_imageContainer__pgJIT {
  transition: transform 150ms ease, box-shadow 150ms ease;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.BlogCard_imageContainer__pgJIT:hover {
    transform: scale(1.02);
  }

.BlogCard_footer__6PQHn {
  color: #666;
}

.LightBoxHeader_header__JkUNi {
  z-index: 10;
  display: flex;
  justify-content: space-between;
  background-color: black;
  color: white;
}

.LightBoxHeader_button___Pg_V {
  z-index: 2;
  color: white;
}

.LightBoxHeader_button___Pg_V:hover {
    color: cyan;
  }

@media (max-width: 550px) {

.LightBoxHeader_hideOnMobile__w7MqE {
    display: none
}
  }

.Gallery_button__Q4_lC {
  z-index: 2;
  color: white;
}

  .Gallery_button__Q4_lC:hover {
    color: cyan;
  }

.Gallery_imageItem__ePY7u {
  margin: auto;
  cursor: pointer;
  object-fit: contain;
  background-color: var(--mantine-color-gray-1);
}

[data-mantine-color-scheme='dark'] .Gallery_imageItem__ePY7u {
  background-color: var(--mantine-color-gray-9);
}

.Gallery_imageItem__ePY7u {

  height: auto;
  width: 100%;
}

.Gallery_grid___vCHI {
  padding: var(--mantine-spacing-lg) var(--mantine-spacing-md);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  gap: 5px;
}

.Gallery_grid___vCHI > * {
    display: grid;
  }

@media (max-width: 1300px) {

.Gallery_grid___vCHI {
    grid-template-columns: repeat(4, 1fr)
}
  }

@media (max-width: 1050px) {

.Gallery_grid___vCHI {
    grid-template-columns: repeat(3, 1fr)
}
  }

@media (max-width: 800px) {

.Gallery_grid___vCHI {
    grid-template-columns: repeat(2, 1fr)
}
  }

@media (max-width: 550px) {

.Gallery_grid___vCHI {
    grid-template-columns: 1fr
}
  }

.Gallery_categoryItem__DuPbY:hover {
    cursor: pointer;
    color: #ababab;
  }

.Gallery_galleryGroups__1MpDY {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: #202020;
}

[data-mantine-color-scheme='dark'] .Gallery_galleryGroups__1MpDY {
  color: white;
}

@media (max-width: 1250px) {

.Gallery_galleryGroups__1MpDY {
    flex-direction: column
}
  }

.Footer_inner__dDTAB {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px var(--mantine-spacing-md);
  max-width: 1300px;
}

  @media (max-width: 550px) {.Footer_inner__dDTAB {
    flex-direction: column
}
  }

.Footer_icons__OQNtr {
  color: #868e96;
}

[data-mantine-color-scheme='dark'] .Footer_icons__OQNtr {
  color: #e9ecef;
}

.Header_height__ELy48 {
  height: 65px;
}

.Header_navigation__FSglr {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  max-width: 1300px;
  margin: auto;
  padding: 0 var(--mantine-spacing-md);
}

@media (max-width: 600px) {

.Header_navigation__FSglr {
    grid-template-columns: 1fr 9fr
}
  }

.Header_list__RE_zn {
  display: inline-flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  gap: 35px;
}

.Header_link__L_vDa:hover {
    cursor: pointer;
    color: #ccc;
  }

.Header_backColor__umR3a {
  background-color: white;
}

[data-mantine-color-scheme='dark'] .Header_backColor__umR3a {
  background-color: var(--mantine-color-dark-9);
}

.Header_sticky___37ut {
  z-index: 1;
  position: fixed;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  animation: Header_slideDown__nJnmZ 0.35s ease-out;
}

@keyframes Header_slideDown__nJnmZ {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@media (max-width: 600px) {

.Header_hiddenMobile__bAdaO {
    display: none
}
  }

@media (min-width: 600px) {

.Header_hiddenDesktop__3w6xA {
    display: none
}
  }

.Header_mobileLink__A6aHw {
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  height: 42px;
  display: flex;
  align-items: center;
  width: 100%;
}

.Header_divider__pp9xx {
  color: var(--mantine-color-gray-1);
}

[data-mantine-color-scheme='dark'] .Header_divider__pp9xx {
  color: var(--mantine-color-dark-5);
}

.WelcomeTextBlock_textWrapper__viuFA span {
  font-size: inherit;
  font-weight: inherit;
}

.index_logo__Z0ACT {
  display: flex;

  flex-direction: column;

  justify-content: space-between;
  align-items: center;
  padding: 80px 0;
}

  @media (max-width: 750px) {.index_logo__Z0ACT {
    padding: 0
}
    .index_logo__Z0ACT h1 {
      font-size: 50px;
    }
  }

.index_section__goTWh {
  padding: 80px 0;
}

[data-mantine-color-scheme='light'] .index_section__goTWh {
    border-bottom: 1px solid var(--mantine-color-gray-1);
}

[data-mantine-color-scheme='dark'] .index_section__goTWh {
    border-bottom: 1px solid var(--mantine-color-dark-9);
}

.index_content__ROCt_ {
  margin: auto;
  max-width: 1300px;
}

.index_grid__LrZtk {
  display: grid;

  grid-template-columns: repeat(3, 1fr);

  gap: 40px;

  padding: var(--mantine-spacing-lg) var(--mantine-spacing-md);
}

@media (max-width: 950px) {

.index_grid__LrZtk {
    grid-template-columns: repeat(2, 1fr);
}
  }

@media (max-width: 750px) {

.index_grid__LrZtk {
    grid-template-columns: 1fr;
}
  }

.index_grid__LrZtk {

  position: relative;
}

.index_graySection__K3YBt {
  background: var(--mantine-color-gray-0);
}

[data-mantine-color-scheme='dark'] .index_graySection__K3YBt {
  background: var(--mantine-color-dark-8);
}

